module.exports = {
  content: [
    './src/components/**/*.{js,jsx,ts,tsx}',
    './src/pages/**/*.{js,jsx,ts,tsx}',
    './src/templates/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {
      colors: {
        brand: {
          'dark-blue': '#25368C',
          blue: '#127EE4',
          'light-blue': '#29BAF8',
          yellow: '#F7C11B',
          black: '#2C2C2C',
          'light-gray': '#F3F3F3',
          gray: '#DBDBDB',
        },
      },
      typography: {
        basic: {
          css: [
            {
              a: {
                color: 'var(--tw-prose-links)',
                textDecoration: 'underline',
                fontWeight: '500',
              },
              strong: {
                color: 'var(--tw-prose-bold)',
                fontWeight: '600',
              },
            },
          ],
        },
      },
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
  ],
}
