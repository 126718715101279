import * as React from 'react'

export type DirectusContextProps = {
  editable: boolean
  directus: DirectusUrls | false
}

class DirectusUrls {
  base: string
  pages: { [key: string]: string } = {
    '/': '/admin/content/homepage',
  }

  constructor(base: string) {
    this.base = base
  }

  get admin(): string {
    return this.base
  }

  get page(): string | false {
    const path = this.pages[location.pathname] || false

    return path ? `${this.base}${path}` : false
  }
}

export const DirectusContext = React.createContext<DirectusContextProps>({
  editable: false,
  directus: false,
})

type DirectusProviderProps = {
  children: React.ReactElement
}

function checkEditable(): boolean {
  if (
    process.env.NODE_ENV === 'development' ||
    location.hostname.endsWith('.gatsbyjs.io') ||
    location.hostname.endsWith('.gtsb.io')
  ) {
    return true
  }

  return !!document?.cookie.match(
    '(^|;)\\s*directus_refresh_token\\s*=\\s*([^;]+)',
  )
}

export const DirectusProvider: React.FC<DirectusProviderProps> = ({
  children,
}) => {
  const editable = !!process.env.DIRECTUS_URL && checkEditable()

  let directus: DirectusUrls | false = false
  if (editable && process.env.DIRECTUS_URL) {
    directus = new DirectusUrls(process.env.DIRECTUS_URL)
  }

  return (
    <DirectusContext.Provider value={{ editable, directus }}>
      {children}
    </DirectusContext.Provider>
  )
}
