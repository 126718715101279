import * as React from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import config from './tailwind.config'
import TailwindDebugBreakpoints from 'tailwind-debug-breakpoints-react'
import { WrapPageElementBrowserArgs, WrapRootElementBrowserArgs } from 'gatsby'
import { DirectusProvider } from './src/providers/directus'
import './dayjs-setup'

import './src/styles/app.css'

const tw = resolveConfig(config)

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  return <DirectusProvider>{element}</DirectusProvider>
}

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <div>
        {element}
        <TailwindDebugBreakpoints screens={tw?.theme?.screens} />
      </div>
    )
  }

  return element
}
