import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/es-mx'

dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.locale('es-mx')
dayjs.tz.setDefault('America/Santiago')
